import {get,post} from './request'
export const getWarningInfo = (code)=>get('/warninginfo/list',{
     groupCode:code
})
export const warninginfoGroup = ()=>get('/warninginfo/group',{})
//首页info
export const getHomeInfo = ()=>get('/home/info',{})
//获取ticket
export const getTicket = ()=>get('/jsapi/ticket',{})
//参数登录
export const loginbywt = (obj)=>get('/user/loginbywt',{
     ...obj
})
//简报状态
export const getStatus = ()=>get('/operationreport/status',{})
//读简报
export const redMove = ()=>get('/operationreport/read',{})
//首页城市体征指标
export const getCstzzb = ()=>get('/home/cstzzb',{})
//首页城市体征指标info
export const getAnalysis = (code)=>get('/quotaanalysis/analysis',{
     groupCode:code
})
export const getQuotalist = (code)=>get('/quotaanalysis/quotalist',{
     groupCode:code
})

export const getWarninglist = (code)=>get('/quotaanalysis/warninglist',{
     groupCode:code
})
//指标详情
export const getQuotadetail = (code)=>get('/quotadetail/info',{
     quotaCode:code
})
//收藏指标
export const getCollectList = (code)=>get('/quotacollection/quotalist',{
     groupCode:code
})
//添加收藏
export const addCollection = (code)=>get('/quotacollection/add',{
     quotaCode:code
})
//删除收藏
export const removeCollection = (code)=>get('/quotacollection/remove',{
     quotaCode:code
})
//使用api获取的id获取token
export const getUser = (code)=>get('/user/login',{
     authCode:code
},{timeout:30000})
export const getLatest = ()=>get('/api/v1/operationreport/latest',{
})
