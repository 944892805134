<template>
    <div class="table2">
      <div class="left" >
          <div class="icon" :style="`background:${iconColor}`"></div>
          <div class="textTitle">{{title}}</div>
      </div>
      <div class="right">
          <div class="num" >{{num}}</div>
          <div class="unit">{{unit}}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  props:{
      iconColor:String,
      title:String,
      num:'',
      unit:String,
      widthMax:'',
      prompt:0,
      icon:''
  },
  methods:{
    
  }
  }
  </script>
  
  <style lang="scss" scoped>
  .table2{
      min-width: 200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .left{
          display: flex;
          align-items: center;
          margin-right: 10px;
          .icon{
              width: 10px;
              height: 10px;
              border-radius:50%;
              margin-right: 11px;
          }
          .textTitle{
              font-size: 24px;
              color: #999999;
          }
      }
      .right{
          display: flex;
          align-items: center;
          .num{
              font-weight: 600;
              font-size: 28px;
              color: #353A45;
              margin-right: 10px;
              img{
                  width: 13px;
                  height: 19px;
              }
          }
          .unit{
              display: flex;
              align-items: center;
              font-size: 24px;
              color: #999999;
              img{
                  width: 33px;
                  height:31px;
              }
          }
      }
  }
  </style>