<template>
  <div class="content">
    <div class="title">
        <div class="title1" :style="`color:${isChangeColor?'#353A45':''}`">
            {{title}}
        </div>
        <div class="msg" :style="`color:${isChangeColor?'#999999':''}`">
            {{msg}}
        </div>
    </div>
    <div class="line">
        <div class="long"></div>
        <div class="short"></div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        title:String,
        msg:String,
        isChangeColor:false,
    }
}
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    margin-left: 30px;
    margin-bottom: 24px;
    .title{
        display: flex;
        margin-bottom: 8px;
        align-items: flex-end;
        .title1{
            font-size: 32px;
            font-weight: 600;
            margin-right: 16px;
        }
        .msg{
            font-size: 24px;
            margin-bottom: 4px;
        }
    }
    .line{
        display: flex;
        .long{
            width: 100px;
            height: 6px;
            margin-right: 8px;
            background: #4083FF;
            border-radius: 6px;
        }
        .short{
            width:12px;
            height: 6px;
            background: #4083FF;
            border-radius: 4px;
        }
    }
}
</style>