<!-- 八大弹窗 -->
<template>
    <van-overlay z-index="999" :show="popShow" @click="popShow = false" :lock-scroll="false">
    <div class="app-pop" @click.stop>
        <div class="app-block">
          <div class="app-content">
            <div class="app-item" v-for="item,index in popData"  :key="index" >
              <div class="app-content-title" >
                {{ item.label }}
                <span class="triangle"></span>
              </div>
              <div class="link"
                @touchstart="gtouchstart(item.url)"
                @touchmove="gtouchmove()"
                @touchend="showDeleteButton()"
              >
              {{ item.url }}
              </div>
            </div>
          </div>
          <div class="app-block-button">
            请长按网站复制到手机浏览器内进行访问
          </div>
      </div>
      <div class="close" @click="popShow = false">
                <img src="@/assets/esc_icon.png" alt="">
        </div>
    </div>
  </van-overlay>
</template>

<script>
import {getTicket} from '@/serve/api.js'
import dd from 'gdt-jsapi';
export default {
  props:{
    popData:[],
  },
  data () {
    return {
      timeOutEvent:null,
      popShow:false,
    };
  },

  components: {},

  computed: {},

  mounted(){},

  methods: {
    show(){
      this.popShow = true
    },
    gtouchstart(v) {
      this.timeOutEvent = setTimeout(  async ()=>{
       this.longPress(v);
      }, 500); //这里设置定时器，定义长按500毫秒触发长按事件
      return false;
    },
    //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
    showDeleteButton() {
      clearTimeout(this.timeOutEvent); //清除定时器
      if (this.timeOutEvent != 0) {
        //这里写要执行的内容（如onclick事件）
        console.log("点击但未长按");
      }
      return false;
    },
    //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
    gtouchmove() {
      clearTimeout(this.timeOutEvent); //清除定时器
      this.timeOutEvent = 0;
    },
    //真正长按后应该执行的内容
    longPress(val) {
      this.timeOutEvent = 0;
      //执行长按要执行的内容，如弹出菜单
      console.log("长按",val);
      this.copyText(val)
    },
    //复制事件
    async copyText(v) {
      //获取ticket
      const res = await getTicket();
        console.log(res);
        //鉴权
            dd.authConfig({
                ticket: res.ticket,
                jsApiList:["copyToClipboard"]
            }).then(res1 =>{
                      console.log(res1,'jsapi');
                       //执行复制
                      dd.copyToClipboard({
                            text:v
                        }).then(res2 => {
                            this.$toast('复制成功')
                            console.log(res2,'执行复制成功输出')
                        }).catch(err => {
                            this.$toast(err)
                        })
            }).
            catch(err =>{
              this.$toast(err)
            })
    },
  }
}

</script>
<style lang='scss' scoped>
.close{
      width: 39px;
      height: 39px;
      border-radius: 50%;
      border: 3px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 27px auto 0px;
      img{
          width: 28px !important;
          height: 28px !important;
          margin: 0 !important;
      }
}
  .app-pop{
    width: 100vw;
    height: 100vh;
    .app-block{
      width: 630px;
      height: 990px;
      border-radius: 20px;
      background-color: #fff;
      color: #000;
      margin: 99px auto 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      padding:40px 38px 38px;
      .app-content{
        overflow: auto !important;
        -webkit-overflow-scrolling: touch !important;
        .app-item{
          margin-bottom: 13px;
        }
        .link{
          overflow-wrap: break-word;
          text-align: left;
          font-size: 24px;
          font-weight: 400;
          color: #1492FF;
        }
        .app-content-title{
          width: fit-content;
          font-size: 29px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 54px;
          height:54px;
          background-color:#D1903E;
          position: relative;
          padding-left:13px;
          padding-right:13px;
          margin-bottom: 8px;
          border-radius: 10px;
        .triangle{
          display: inline-block;
          width:0px;
          height:0px;
          border-left:9px solid transparent;
          border-right:9px solid transparent;
          border-top:9px #D1903E solid;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 54px;
        }
      }
      }
      .app-block-button{
        width: 510px;
        border-top: 2px solid rgba(216,216,216,0.5);
        box-sizing: border-box;
        padding-top: 23px;
        font-weight: 500;
        font-size: 28px;
        color: #353A45;
        margin: 0 auto;
      }
    }
}
</style>