<template>
  <div class="tableIcon">
    <img :src="require(`../../assets/icon_${iconColor}.png`) " alt="" align='center'>
    <span>{{title}}</span>
  </div>
</template>

<script>
export default {
props:{ 
    iconColor:String,
    title:String
}
}
</script>

<style lang="scss" scoped>
.tableIcon{
    margin-bottom: 11px;
    text-align: left;
}
img{
     width: 26px;
     height: 26px;
     margin-right: 3px;
}
span{
  color: #353A45;
  font-size: 24px;
  font-weight: 500;
}
</style>