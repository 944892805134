<template>
   <div class="Login">
    <div v-if="queryObj.employeeCode" :style="{backgroundColor:'#fff',fontSize:'13px'}">
      登录中...
     </div>
    <div v-else>
      <div class="from-text">您好, &nbsp;欢迎登录</div>
              <van-form @submit="onSubmit">
                  <div class="title">
                  <img :style="`marginRight:${$px2rem('22px')};`" src="@/assets/title_icon_left.png" alt="">
                      账号密码登录
                  <img :style="`marginLeft:${$px2rem('22px')};`" src="@/assets/title_icon_right.png" alt="">
                  </div>
                  <van-field
                      v-model="username"
                      placeholder="请输入账号"
                      :rules="[{ required: true, message: '' }]"
                  />
                  <van-field
                      v-if="!showPass"
                      v-model="password"
                      type="password"
                      placeholder="请输入密码"
                      @click-right-icon='()=>{showPass=true}'
                      right-icon="closed-eye"
                      :rules="[{ required: true, message: '' }]"
                  />
                  <van-field
                      v-else
                      v-model="password"
                      @click-right-icon='()=>{showPass=false}'
                      placeholder="请输入密码"
                      right-icon="eye-o"
                      :rules="[{ required: true, message: '' }]"
                  />
                  <div class="submitDiv">
                      <van-button  block type="info" native-type="submit">登录</van-button>
                  </div>
              </van-form> 
    </div>
     
    </div>
</template>

<script>
import dd from "gdt-jsapi";
import {getUser,loginbywt} from "@/serve/api.js"
export default {
  data() {
    return {
      username:'',
      password:'',
      showPass:false,
      queryObj:{
        employeeCode:'',
        timestamp:'',
        signature:'',
      }
    };
  },
  methods: {
    onSubmit(){
      if(this.username === 'ywtgadmin' && this.password ==='3fNREZ75TzsGA4sF@78@'){
          if(process.env.NODE_ENV === 'development'){
                this.testLogin();
            }else if(process.env.NODE_ENV === 'production'){
                this.testLogin();
                // this.login();
            }
      }else{
        this.$toast('请输入正确账号密码')
      }
    },
    async queryLogin(){
      try{
            const res2 = await loginbywt(this.queryObj)
            console.log(res2,'获取token')
              //存入token和expireTime
            window.localStorage.setItem("token", res2.token);
            window.localStorage.setItem("expireTime", res2.expireTime);
            if (window.localStorage.getItem("token")) {
              //当存入token时跳转home
              this.$store.commit("hideLoading");
              // this.$router.push("/home");
              dd.ready(() => {
              dd.version()
                .then((res) => {
                      console.log('浙');
                      dd.replacePage({
                      url: '/#/home'
                      }).then(res => {
                          console.log(res)
                      }).catch(err => {
                          console.log(err);
                      })
                })
                .catch((error) => {
                    //“非浙政钉环境”
                    console.log('非浙');
                    window.location.replace('/#/home')
                });
            });
         
      }
      }catch(e){
            //getUser catch
            this.$store.commit("hideLoading");
            this.$toast(e.message);
          }
    },
    init() {
      console.log("从home过来了");
      // dd.closePage()
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //       console.log(err)
      //   });
      dd.goBack().then(res => {
          console.log(res,'goBack')
        }).catch(err => {})
    },
    //登录
    login() {
      console.log('login方法');
      //判断是否有token是否有expireTime
      if (
        window.localStorage.getItem("token") &&
        window.localStorage.getItem("expireTime")&&
        new Date() <= new Date(window.localStorage.getItem("expireTime"))
      ) {
        //有token时且时间没有过期直接去首页
          // this.$router.push('/home')
          dd.ready(() => {
              dd.version()
                .then((res) => {
                      console.log('浙');
                      dd.replacePage({
                      url: '/#/home'
                      }).then(res => {
                          console.log(res)
                      }).catch(err => {
                          console.log(err);
                      })
                })
                .catch((error) => {
                    //“非浙政钉环境”
                    console.log('非浙');
                    window.location.replace('/#/home')
                });
            });
         
      } else {
        //没有token或者登录事件过期 走登录
        console.log("没有token或者时间过期调用登录方法");
        this.$store.commit("showLoading", "登录中...");
        if(this.queryObj.employeeCode&&this.queryObj.timestamp&&this.queryObj.signature){
          console.log('有employeeCode走queryLogin');
          this.queryLogin();
        }else{
          console.log('免登');
          this.getAuthCode();
        }
      }
    },
    async testLogin() {
        //本地测试
      try{
          if(this.queryObj.employeeCode&&this.queryObj.timestamp&&this.queryObj.signature){
            console.log('参数登录');
            var res2 = await loginbywt(this.queryObj);
          }else{
            console.log('免登');
            var res2 = await getUser("46df06e39e82496f8d2ae97cbb43c5be")
          }
          //存入token
          window.localStorage.setItem("token", res2.token);
          window.localStorage.setItem("expireTime", res2.expireTime);
          //当有token
          if(window.localStorage.getItem("token") == res2.token){
            dd.ready(() => {
              dd.version()
                .then((res) => {
                      console.log('浙');
                      dd.replacePage({
                      url: '/#/home'
                      }).then(res => {
                          console.log(res)
                      }).catch(err => {
                          console.log(err);
                      })
                })
                .catch((error) => {
                    //“非浙政钉环境”
                    console.log('非浙');
                    window.location.replace('/#/home')
                });
            });
         
          }
      }catch(e){
        this.$toast(e.message);
      }
    },
    //调用钉钉api获取authCode
    getAuthCode(){
      dd.ready(()=>{
        dd.getAuthCode().then(async res=>{
          console.log(res,'获取authCode');
          try{
            console.log('拿到authcode开始调用getUser');
            const res2 = await getUser(res.code);
            console.log(res2,'获取token')
              //存入token和expireTime
            window.localStorage.setItem("token", res2.token);
            window.localStorage.setItem("expireTime", res2.expireTime);
            if (window.localStorage.getItem("token")) {
              //当存入token时跳转home
              this.$store.commit("hideLoading");
              dd.ready(()=>{
                dd.replacePage({
                url:'/#/home'
                }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err);
                })
              })
            }
          }catch(e){
            //getUser catch
            this.$store.commit("hideLoading");
            this.$toast(e.message);
          }
        }).catch((err)=>{
          this.$store.commit("hideLoading");
          this.$toast(err);
        })
      })
    },
  },
  async created() {
    this.queryObj = this.$route.query
    if(process.env.NODE_ENV === 'development'){
        // this.testLogin();
    }else if(process.env.NODE_ENV === 'production'){
        // this.login();
    }
  },
  mounted(){
    if(this.queryObj.employeeCode&&this.queryObj.timestamp&&this.queryObj.signature){
      this.testLogin()
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "home") {
        vm.init();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.submitDiv{
  margin-top: 150px;
}
      .Login{
          // height: 100vh;
          background: url(@/assets/bg.png) no-repeat;
          background-size: 100% 340px;
          overflow: scroll;
          text-align: left;
          .from-text{
              font-size: 42px;
              font-weight: 600;
              color: #ffff;
              margin-top: 114px;
              margin-left: 30px;
          }
              .van-form{
                  width:750px;
                  height:calc(100vh - 276px);
                  margin-top: 104px;
                  background: #fff;
                  border-radius: 70px 0px 0px 0px;
                  box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
                  box-sizing: border-box;
                  padding: 100px 20px 0 20px;
                  .title{
                      font-size:28px;
                      font-weight: 500;
                      text-align: center;
                      color: #353A45;
                      margin-bottom: 120px;
                  }
                  .van-field{
                      width:630px;
                      padding: 49px 0 40px 0;
                      margin: 0 auto;
                      font-weight: 400;
                      color: #999999;
                      font-size: 28px;
                  }
                  .van-button{
                      width: 690px;
                      height: 72px;
                      background: #4083FF;
                      border-radius: 8px;
                      font-weight: 400;
                      color: #FFFFFF;
                      font-size: 28px;
                  }
              }
      }
</style>