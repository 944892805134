import Vue from 'vue'
import Vuex from 'vuex'
import { Toast } from 'vant'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
    showLoading(state,message='加载中..'){
      Toast({
        type: "loading",
        message:message,
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
      })
    },
    hideLoading(state){
      Toast.clear()
    }
  },
  actions: {
  },
  modules: {
  }
})
