<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.name" v-if="$route.fullPath && $route.meta.keepAlive"  />
    </keep-alive>
      <router-view :key="$route.name" v-if="$route.fullPath && !$route.meta.keepAlive" />
  </div>
</template>
<script>
import dd from 'gdt-jsapi';
export default {
  mounted(){
    // if(this.$route.meta.title){
    //   document.title = this.$route.meta.title
    // }
  },
  created(){
  },
  computed: {
  },
  watch:{
    // $route(to){
    //   dd.setTitle({
    //       title: to.meta.title
    //   }).then(res => {
    //       console.log(res)
    //   }).catch(err => {})
    //     document.title = to.meta.title
    // }
  }
}
</script>
<style lang="scss">
::-webkit-input-placeholder { 
/* WebKit browsers，webkit内核浏览器 */
color: rgb(184, 184, 184); 
} 
:-moz-placeholder { 
/* Mozilla Firefox 4 to 18 */ 
color: rgb(184, 184, 184); 
} 
::-moz-placeholder { 
/* Mozilla Firefox 19+ */ 
color: rgb(184, 184, 184); 
} 
:-ms-input-placeholder { 
/* Internet Explorer 10+ */ 
color: rgb(184, 184, 184); 
}
.van-popup{
  overflow: hidden;
}
.van-picker-column__item{
  font-size: 26px;
}
.van-toast__text{
  padding: 10px;  
  font-size: 20px;
}
.van-loading__spinner{
  width: 50px;
  height: 50px;
}
.van-toast--loading {
  width: 150px;
  height: 150px;
}
.van-picker__toolbar {
  height:81px;
  .van-picker__cancel,.van-picker__confirm,.van-ellipsis{
    font-size: 30px;
    line-height: 80px;
  }
  .van-picker__confirm{
    color: rgb(61, 127, 254);
  }
}

#app {
  font-family:"PingFangSC-Medium", "PingFang SC", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none;
}
*{
  margin: 0;
  padding: 0;
}
</style>
