 var arr = [
    {
        label: '党政统领', 
        children: [
            /*{ label: '宁波市人大工作大数据', url: 'https://snpc.zhijiasoft.com/media/big_data/#/preview/eb91f7d4-a271-11eb-9df0-00163e0ccf3e' },*/
            /*{ label: '宁波市政协情况展示', url: 'https://zxtest.zhijiasoft.com/h5/zx_pc_public/#/big-screen-pic/survey' },*/
            /*{ label: '精准画像', url: 'https://szshdn.fgw.ningbo.gov.cn/#/ssocsdn' },*/
            { label: '智慧政协', url: 'http://183.134.232.162/h5/zx_pc/static/page/login/index.html' },
            { label: '浙里甬人大', url: 'https://zhrd.nbrd.gov.cn/media/npc_pc_new_all/#/login' },
            { label: '危化品全链条风险智控', url: '10.68.200.212/whpqltaqfxzk/#/charts'},
            { label: '镇街一件事', url: 'https://zjbb.fh.gov.cn/login?redirect=%2Findex'},
            { label: '360劳动争议多元化解应用', url: 'https://ygh.nbgh.gov.cn/cas/login?service=https://ldzy.nbgh.gov.cn/ldzy/api/cas/login'},
            { label: '文明城市创建“常态长效”', url: 'https://zzd.zhuwentec.com/zzdnb/index_index.html'},
            // { label: '市政务云计算中心运维管理', url: 'http://10.68.145.26/index2.html' },
            { label: '重大决策执行智控', url:'https://zdrw.nbjb.gov.cn/taskChainui/home#/login'},
            { label: '数字化绩效考评应用场景', url: 'http://10.68.205.182/#/login'},
            { label: '知情明政服务应用', url: 'https://zqmz.nbtzb.gov.cn/#/login'},
            { label: '海曙81890求助服务中心', url: 'https://sht3.81890.org.cn/81890-manage/#/user/login'},
            { label: '最多报一次（鄞州基层一表通）', url: 'http://10.74.226.60:84/#/'},
            { label: '宁波市市政务云计算中心', url: 'http://10.68.145.26/index2.html'},
        ]
    },
    {
        label: '执法监管', 
        children: [
            { label: '一体化综合执法', url: 'https://cgtest.turingsingularity.com/#/cockpit' },
            { label: '甬房监测', url: 'http://183.136.157.14:9007/webserver/app_ecem/index.html#/main'},
            { label: '移动微法院质效数据', url: 'https://wfyml.nbcourt.gov.cn/au/bi-web/#/'},
            { label: '综合执法处罚办案协调应用', url: 'http://cg.turingsingularity.com/#/'},
            { label: '电动自行车超速与非机动车及行人违法管控', url: 'https://www.nbgxdcjg.com.cn:8090/#/login'},
            { label: '建筑垃圾处置智管', url: 'http://115.231.59.86' },
            { label: '交通运输企业违规处理监管系统', url: 'http://10.68.130.18:10025/ldp-web/nbzhjt.html' },
            { label: '农贸市场智慧化AI监管系统', url: 'http://60.190.56.131/nb_market_system/login.ftl' },

        ]
    },
    {
        label: '综合治理', 
        children: [
            { label: '宁波市综合指挥平台', url: 'http://10.68.129.154:8119/pages/NSquared/#/index/8a9348b478007d30017801e7d9323a1c' },
            { label: '建设与整治', url: 'http://datav.aliyuncs.com/share/5bdd580015d7c3520e9ecfa29603b7d6' },
            { label: '鄞州基层一表通', url: 'http://10.74.226.60:84/#/' },
            { label: '宁波市城市精细化综合管理协同应用', url: 'http://60.12.18.252:10000/#/login' },
            { label: '宁波市垃圾分类服务监管系统', url: 'http://183.134.253.199/?token=eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxNzQzLCJ1c2VyX2tleSI6IjEwYmZjZTFjLTg0M2MtNDg5MS05NDQyLTgwOTg0MDViNzUzNCIsInVzZXJuYW1lIjoiR0VfOTNkN2Q1YTE0OWMyNDE1OTlhYzhkNDg0MDBjN2QzYmYifQ.6WX6rjiA7uiJU5pdFafbuZkVF9lpoTrZ3bcwOk5H7Vwzin_2bZM245ih6831EsqvrpGo9Xb67L9sNCXEUBrm_Q' },
            // { label: '电梯智能化AI', url: 'https://board-nbjbcy.yun-ti.com/#/index' },
            // { label: '闲置农房盘活场景应用', url: 'https://xznfeph.xiangshan.gov.cn:8010/gisqBI/view.html?tp=public&biId=c84a96bef75a40d0b8d2d751bf18e342&appId=00000001000100010001000000000001#/' },
            // { label: '建设与整治', url: 'http://datav.aliyuncs.com/share/5bdd580015d7c3520e9ecfa29603b7d6' },
            // { label: '餐饮油烟', url: 'http://screenyouyan.nbzhixing.cn' },
            // { label: '宁波市综合管线平台', url: 'http://10.19.67.59:8082/NbIntegSys' },
            { label: '天巡地查场景', url: 'https://txdc.zrzyt.zj.gov.cn:8000/bootstraps/login/login.html' },
            // { label: '城市精细化综合管理协同应用', url: 'http://60.12.18.252:10000/#/login' },
            { label: '公共安全视频图形共享总平台', url: 'http://10.68.177.66:8080/leader/maps/share/search', isBlank: true }
        ]
    },
    {
        label: '平安应急', 
        children: [
            { label: '疫情防控', url: 'http://10.68.207.148/views/yqfk/index.html' },
            { label: '台灾防御行动与指挥', url: 'https://easyv.dtstack.com/workspace/shareScreen/eyJzY3JlZW5JZCI6MzMzMDN9'},
            { label: '数字孪生', url: 'http://10.68.178.130:8677/feapp-DigitalTwin/#/personnel' },
            { label: '危化品全链条安全风险智控', url: 'http://10.68.200.212/whpqltaqfxzk/#/charts' },
            { label: '安全生产风险闭环管控协同应用', url: 'http://10.68.163.130/ybEmergencyPortal/login' },
        ]
    },
    {
        label: '民生社会', 
        children: [
            { label: '食安追溯联动', url: 'https://10.74.36.148/pc/#/login' },
            { label: '养老服务监测', url: 'https://ylyl.nbyz.cn/screen/' },
            { label: '集市驿站', url: 'http://jsyz.ninghai.gov.cn/dp' },
            { label: '就业形势分析', url: 'http://10.19.149.251:7003/lemis/nbjy/main2.html' },
            { label: '宁波市甬城泊车平台', url: 'http://10.19.72.185:2280/yctc-web-bigscreen-zww/#/' },
            { label: '艺术振兴乡村应用', url: 'https://nhdsj.ninghai.gov.cn/artvillageScreen/#/' },
            /*{ label: '鄞州区人员流动监测平台', url: 'https://aladdin.zj.chinamobile.com/dtrk/yzrk/#/' },*/
            { label: '特殊老人关怀', url: 'http://10.68.206.234:7001/share/0e1f8499-3bfb-484c-9a42-ac9dd1c6262e' },
            { label: '宁波健康大脑', url: 'http://zhjgzsdp.nbwjw.gov.cn/gbigscreenapp/#/wisdom/brain' },
            { label: '“新居民”一件事', url: 'https://xsm.fzggw.zj.gov.cn/redirect/login.html?token=kfLM3gtLSaYWg9dbnnfV6nkvHzNptCxq' },
            { label: '宁波市网络民生服务平台', url: 'http://mliao.admin.zhanyaa.com/login.html' },
            { label: '新材云创', url: 'https://www.nmstart.com/' },
            // { label: '天一甬宁保', url: 'http://datav.shie.com.cn:8000/share/91d72408-d4c7-4840-a799-3fad52916be0' },
        ]
    },
    {
        label: '经济运行', 
        children: [
            { label: '甬金通', url: 'https://www.phcredit.com.cn/phjr/shop/#/home' },
            { label: '天一阁数字孪生', url: 'https://www.tianyige.com.cn' },
            { label: '数据宁波', url: 'https://sjnb.ningbo.gov.cn/tjjsec/index.html#/screen' },
            { label: '工业经济运行监测', url: 'http://10.74.16.36:8080/login' },
            { label: '数智菜篮子', url: 'http://61.130.44.206/szclz/szclz-ui/html/main.html' },
            { label: '跨境电商综合服务', url: 'http://admin.cefs-online.com/scf/admin/user/login' },
            { label: '艺术振兴乡村', url: 'https://nhdsj.ninghai.gov.cn/artvillageScreen/#/' },
            { label: '鄞州楼宇经济应用', url: 'https://lyjj.nbyz.cn/web/dist2/index.html#/login' },
            /*{ label: '数买通', url: 'http://meu.trainer.eportyun.com/screen/index' },*/
            // { label: '监测防控系统', url: 'http://10.74.28.166:9002/index.html#/home' },
            { label: '产融服务', url: 'http://10.74.33.77:9085/ningbo/screen/index.html' },
            // { label: '长三角区域金融风险联防联控系统', url: 'http://10.74.28.166:9002/index.html#/defence' },
            { label: '金融顾问', url: 'http://10.74.33.77:9085/ningbo/screen/fuwu.html' },

        ]
    },
    {
        label: '生态低碳', 
        children: [
            { label: '今日水利', url: 'https://easyv.dtstack.com/workspace/shareScreen/eyJzY3JlZW5JZCI6MTQyMTAwfQ==' },
            { label: '气象预报', url: 'http://datav.aliyuncs.com/share/618d2e460a7edac6f458e91b5545524f' },
            { label: '环境保护', url: 'http://datav.aliyuncs.com/share/daf82ded6c9993e4e8a1ef4961cefebf' },
            { label: '数智治水（甬有碧水）', url: 'http://10.74.45.83:8081/yybs/login' },
            { label: '能源数据可视化分析', url: 'https://10.68.201.33' },
            { label: '防御调度分析', url: 'https://easyv.dtstack.com/workspace/shareScreen/eyJzY3JlZW5JZCI6MzMzMDN9' },
            { label: '水资源分析', url: 'https://easyv.dtstack.com/workspace/shareScreen/eyJzY3JlZW5JZCI6MzMzMDN9' },
            { label: '水利工程', url: 'https://easyv.dtstack.com/workspace/shareScreen/eyJzY3JlZW5JZCI6MzMzMDN9' }
        ]
    },
    {
        label: '开放港口', 
        children: [
            { label: '贸易监测', url: 'https://www.nbsinglewindow.cn/' },
            { label: '海上丝路指数', url: 'http://datav.aliyuncs.com/share/58ac06d2ad024386d790b4f56b71761f' },
            { label: '数字港航', url: 'http://59.202.38.198/login' },
            { label: '数字渔港', url: 'http://10.34.68.40:18013/home?freeLogin' },
            { label: '口岸通关', url: 'http://www.nbsinglewindow.cn/portdp/dp/dp!index.do?tdsourcetag=s_pcqq_aiomsg' }

        ]
    },
]
export default arr