import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/Login',
  },
  {
    path: '/Login',
    component:Login,
    meta:{
      title:'登录'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta:{
      title:'宁波市“一网统管”'
    }
  },
  {
    path: '/moveList',
    name: 'MoveList',
    meta:{
      title:'运行简报列表'
    },
    component: () => import(/* webpackChunkName: "move" */ '@/views/MoveList.vue')
  },
  {
    path: '/move',
    name: 'Move',
    meta:{
      title:'运行简报'
    },
    component: () => import(/* webpackChunkName: "move" */ '@/views/Move.vue')
  },
  {
    path: '/collection',
    name: 'Mollection',
    meta:{
      title:'收藏指标清单',
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "metrics" */ '@/views/CollectionMetrics.vue')
  },
  {
    path: '/metricsInfo',
    name: 'MetricsInfo',
    meta:{
      title:'指标详情'
    },
    component: () => import(/* webpackChunkName: "metrics" */ '@/views/MetricsInfo.vue')
  },
  {
    path: '/safetyIndexSignboard',
    name: 'SafetyIndexSignboard',
    meta:{
      title:'指标分析看板',
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "metrics" */ '@/views/SafetyIndexSignboard/SafetyIndexSignboard.vue')
  },
  {
    path: '/warningInfo',
    name: 'WarningInfo',
    meta:{
      title:'指标预警列表'
    },
    component: () => import(/* webpackChunkName: "warning" */ '@/views/WarningInfo.vue')
  },
]
const router = new VueRouter({
  // mode:'history', 
  routes
})


export default router
