import 'babel-polyfill';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import * as echarts from 'echarts/core';
import VConsole from 'vconsole';
import Bounce from 'vue-bounce'
import { GridComponent,LegendComponent,MarkLineComponent,TitleComponent,TooltipComponent } from 'echarts/components';
import { BarChart,LineChart,PieChart,RadarChart} from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VueRouter from 'vue-router'
import VueClipBoard from 'vue-clipboard2'
VueClipBoard.config.autoSetContainer = true
Vue.use(VueClipBoard)
echarts.use([GridComponent, BarChart, CanvasRenderer,LegendComponent,LineChart,PieChart,RadarChart,MarkLineComponent,TitleComponent,TooltipComponent]);
import { Button,Swipe, SwipeItem,Overlay,Popup,DatetimePicker,Picker,Form,Field,Toast,Loading,Popover  } from 'vant'
Vue.use(Bounce)
Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Overlay)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Form)
Vue.use(Field)
Vue.use(Toast)
Vue.use(Loading)
Vue.use(Popover)



const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {})
};
Vue.use(VueRouter)


  // const vConsole = new VConsole();
  // Vue.use(vConsole)
// if (process.env.VUE_APP_MODE == "test") {
  // console.log(process.env.VUE_APP_MODE,'vconsole');

// }

// 内敛样式rem适配方法
function px2rem(px){
  if(/%/ig.test(px)){ // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  }else{
    return (parseFloat(px) / 75) + 'rem'
  }
}
//echarts单位适配
function fontSize(res) {
    let clientWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (!clientWidth) return;
    let fontSize = 100 * (clientWidth / 750);
    return res * fontSize;
}
Vue.prototype.$fontSize = fontSize
Vue.prototype.$px2rem = px2rem 
Vue.prototype.$echarts = echarts
Vue.prototype.$toast = Toast; 
Vue.config.productionTip = false
//清除运行简报记录
function unRead(){
    if(window.localStorage.getItem('token')){
      fetch(`https://rkrl.nbbigdata.com/dingtalkgov/api/v1/operationreport/unRead`,{
        method:'get',
        headers:{
          'Us-Token':window.localStorage.getItem('token')
        }
      }).then(res=>{
        if(res.status == 200){
          console.log('清除成功');
        }else{
          console.log('清除失败',res);
        }
      }).catch(err=>{
        console.log(err,'清除失败');
      })
    }else{
      console.log('没有token');
    }
}
window.unRead = unRead
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
